import Vue from 'vue'
import VueRouter from 'vue-router'
// import VueGtag from 'vue-gtag'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: (to, from, next) => {
      document.title = 'CLOVER | Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "homes" */ '../views/Home.vue'),
  },
  {
    path: '/bolsa',
    name: 'Bag',
    beforeEnter: (to, from, next) => {
      document.title = 'Bolsa | Clover Tienda Online Oficial'
      next()
    },
    component: () => import(/* webpackChunkName: "homes" */ '../views/Bag.vue'),
  },
  {
    path: '/comprar/:cartId-:token',
    name: 'Comprar',
    component: () =>
      import(/* webpackChunkName: "Comprar" */ '@/views/Comprar.vue'),
  },
  {
    path: '/checkout/auth/:tokenCart',
    name: 'PaymentEmailAnonymous',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "PaymentAnonymous" */ /*'@/views/Payment.vue'*/ '@/views/CheckoutAnonimo.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/checkout/profile/:tokenCart',
    name: 'PaymentProfileAnonymous',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "PaymentAnonymous" */ /*'@/views/Payment.vue'*/ '@/views/CheckoutAnonimo.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/checkout/shipping-address/:tokenCart',
    name: 'PaymentShippingAddressAnonymous',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "PaymentAnonymous" */ /*'@/views/Payment.vue'*/ '@/views/CheckoutAnonimo.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/checkout/payment/:tokenCart',
    name: 'PaymentAnonymous',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "PaymentAnonymous" */ /*'@/views/Payment.vue'*/ '@/views/CheckoutAnonimo.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/checkout/summary-payment/:tokenCart',
    name: 'PaymentSummaryAnonymous',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "PaymentAnonymous" */ /*'@/views/Payment.vue'*/ '@/views/CheckoutAnonimo.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/checkout',
    name: 'Checkout',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/checkout/auth',
    name: 'PaymentEmail',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/checkout/profile',
    name: 'PaymentProfile',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/checkout/shipping-address',
    name: 'PaymentShippingAddress',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/checkout/payment',
    name: 'Payment',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/checkout/summary-payment',
    name: 'PaymentSummary',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/product-:url/agregar-valoracion',
    name: 'AddComment',
    beforeEnter: (to, from, next) => {
      document.title = 'Valoración de producto | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "AddComment" */ '@/views/ProductEvaluation.vue'
      ),
  },
  {
    path: '/product-:url',
    name: 'ProductDetail',
    component: () =>
      import(/* webpackChunkName: "homes" */ '../views/ProductDetail.vue'),
  },
  {
    path: '/pedido-detalle',
    name: 'PedidoDetalle',
    beforeEnter: (to, from, next) => {
      document.title = 'Detalle de pedido | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "PedidoDetalle" */ '@/views/OrderDetail.vue'),
  },
  {
    path: '/respuesta-bancolombia',
    name: 'PaymentQuota',
    beforeEnter: (to, from, next) => {
      document.title = 'Resumen de compra | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "PaymentQuota" */ '@/views/PaymentQuota.vue'),
  },
  {
    path: '/respuesta-bancolombia-pedido',
    name: 'PaymentQuota',
    beforeEnter: (to, from, next) => {
      document.title = 'STIRPE | Respuesta checkout'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentQuota" */ '@/views/OrderDetailBancolombia.vue'
      ),
  },
  {
    path: '/abono-cupo-detalle',
    name: 'PaymentQuotaDetail',
    beforeEnter: (to, from, next) => {
      document.title = 'Resumen de compra | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentQuotaDetail" */ '@/views/PaymentQuotaDetail.vue'
      ),
  },
  {
    path: '/cupoexpress',
    name: 'CupoExpress',
    hidden: true,
    component: () =>
      import(/* webpackChunkName: "CupoExpress" */ '@/views/CupoExpress.vue'),
    beforeEnter: (to, from, next) => {
      document.title = 'Cupo Personal | Clover Tienda Online Oficial'
      next()
    },
    meta: { requireAuth: false },
  },
  {
    path: '/tiendas',
    name: 'Tiendas',
    beforeEnter: (to, from, next) => {
      document.title = 'Tiendas | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "Tiendas" */ '../views/Tiendas.vue'),
  },
  {
    path: '/perfil',
    name: 'Profile',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Perfil | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue'),
    meta: { requireAuth: false },
  },
  {
    path: '/perfil-mis-pedidos',
    name: 'ProfileOrders',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Pedido | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "ProfileOrders" */ '@/views/ProfileMisPedidos.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/perfil-mis-direcciones',
    name: 'ProfileAddress',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Direcciones | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "ProfileAddress" */ '@/views/ProfileMisDirecciones.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/perfil-cambiar-clave',
    name: 'CambiarClave',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Cambio de contraseña | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "CambiarClave" */ '@/views/ProfileClave.vue'),
    meta: { requireAuth: false },
  },
  {
    path: '/perfil-mis-favoritos',
    name: 'PerfilFavorites',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Favoritos | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PerfilFavorites" */ '@/views/ProfileMisFavoritos.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/perfil-mi-credito',
    name: 'PerfilCredit',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Cupo | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PerfilCredit" */ '@/views/ProfileMiCredito.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/devoluciones',
    name: 'Devoluciones',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Devoluciones | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "Devoluciones" */ '@/views/Devoluciones.vue'),
    meta: { requireAuth: false },
  },
  {
    path: '/preguntas-frecuentes',
    name: 'PreguntasFrecuentes',
    hidden: true,
    component: () =>
      import(
        /* webpackChunkName: "PreguntasFrecuentes" */ '@/views/PreguntasFrecuentes.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/politica-:politica',
    name: 'HabeasData',
    component: () =>
      import(
        /* webpackChunkName: "HabeasData" */ '@/views/PrivacyPolicies.vue'
      ),
  },
  {
    path: '/rastrear-pedido',
    name: 'RastrearPedido',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Seguimiento de pedido | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "RastrearPedido" */ '@/views/RastrearPedido.vue'
      ),
    meta: { requireAuth: false },
  },
  {
    path: '/respuesta-addi/:referenceCode',
    name: 'PaymentAddy',
    beforeEnter: (to, from, next) => {
      document.title = 'CLOVER | Respuesta checkout addi'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentQuota" */ '@/views/OrderDetailAddi.vue'
      ),
  },
  {
    path: '/respuesta-quac/:referenceCode',
    name: 'PaymentQuac',
    beforeEnter: (to, from, next) => {
      document.title = 'CLOVER | Respuesta checkout quac'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentQuac" */ '@/views/OrderDetailQuac.vue'
      ),
  },
  {
    path: '/respuesta-sistecredito/:referenceCode',
    name: 'PaymentSistecredito',
    beforeEnter: (to, from, next) => {
      document.title = 'CLOVER | Respuesta checkout sistecredito'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentSistecredito" */ '@/views/OrderDetailSistecredito.vue'
      ),
  },
  {
    path: '/infocupo',
    name: 'InfoCupo',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Paga tu cuota | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "InfoCupo" */ '@/views/InfoCupo.vue'),
    meta: { requireAuth: false },
  },
  {
    path: '/estadocuenta/:token',
    name: 'EstadoCuenta',
    beforeEnter: (to, from, next) => {
      document.title = 'Estado de cuenta | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "EstadoCuenta" */ '@/views/EstadoCuenta.vue'),
  },
  {
    path: '/franquicias',
    name: 'Franquicia',
    hidden: true,
    component: () =>
      import(/* webpackChunkName: "Franquicia" */ '@/views/Franquicia.vue'),
    meta: { requireAuth: false },
    beforeEnter: (to, from, next) => {
      document.title = 'Franquicia | Clover Tienda Online Oficial'
      next()
    },
  },
  {
    path: '/guia-de-regalos',
    name: 'GuiaDeRegalos',
    hidden: true,
    component: () =>
      import(/* webpackChunkName: "Franquicia" */ '@/views/GuiaRegalos.vue'),
    meta: { requireAuth: false },
    beforeEnter: (to, from, next) => {
      document.title = 'Guía de regalos | Clover Tienda Online'
      next()
    },
  },
  {
    path: '/:collection',
    name: 'StoreCollection',
    component: () =>
      import(/* webpackChunkName: "homes" */ '../views/StoreClover.vue'),
  },
  {
    path: '/:pathError',
    name: 'Error',
    beforeEnter: (to, from, next) => {
      document.title = 'Page not found | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "Error" */ '@/views/Page404.vue'),
  },
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      document.title = 'Page not found | Clover Tienda Online Oficial'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "Error" */ '@/views/Page404.vue'),
  },
]

// Vue.use(VueGtag, {
//   includes: [],
//   config: { id: 'GTM-PK5LKFN' },
// })

const router = new VueRouter({
  mode: 'history',
  routes,
  async scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.path == from.path) {
        return {}
      } else {
        return { x: 0, y: 0, behavior: 'auto' }
      }
    }
  },
})

export default router
